import React, { useState, useEffect, useCallback } from "react";
import { Link } from "react-router-dom";
import "../assets/instantquote.css";
import Cookies from "universal-cookie";
import { axiosRequest } from "../../api";
import { baseURL, ADMINURL } from "../../constant";
import moment from "moment/moment";

function InnerInstantQuote(){
  const [deliverydate, setDeliverydate] = useState();
  const cookies = new Cookies();

  // const [deliverydate, setDeliverydate] = useState("30-07-2023");
  const [wordlength, setWordlength] = useState("250");
  const [price, setPrice] = useState("12.5");
  const handleChange = (event) => {
    cookies.set("serviceType", event.target.value, { path: "/" });
    // setDeliverydate(event.target.value);
    // 👇 Get input value from "event"
    if (event.target.value == 1) {
      //  setDeliverydate('30-07-2023');
      // let d = new Date();
      //  d.setTime(d.getTime() + (99*60*1000));
      //cookies.set('deliverydate', deliverydate, { path: '/' });
      // Cookies.set('deliverydate', deliverydate, { expires: 7 });
    } else if (event.target.value == 2) {
      //  Cookies.set('deliverydate', deliverydate, { expires: 7 });
      // cookies.set('deliverydate', deliverydate, { path: '/' });
      //  setDeliverydate('05-08-2023');
    } else {
      // Cookies.set('deliverydate', deliverydate, { expires: 7 });
      //  cookies.set('deliverydate', deliverydate, { path: '/' });
      //  setDeliverydate('10-08-2023');
    }
  };
  useEffect(() => {
    axiosRequest
      .get(`${baseURL}/getMaxDateBeforeExpedited/250/UTC`)
      .then(function (response) {
        const formattedDeliveryDate = moment(response.data).format(
          "DD MMM YYYY"
        );
        setDeliverydate(formattedDeliveryDate);
        cookies.set("deliverydate", response.data, { path: "/" });
      })
      .catch(function (error) {});
    const loadSetting = async () => {
      const response = await axiosRequest.get(`${baseURL}/getsetSetting`);
      cookies.set("setting", response.data, { path: "/" });
    };

    cookies.set("wordlength", "250", { path: "/" });
    cookies.set("price", "12.5", { path: "/" });
    cookies.set("selected", 1, { path: "/" });
    cookies.set("editorFees", "5", { path: "/" });
    cookies.set("ratio", "1", { path: "/" });
    cookies.set("jobType", "Basic Service", { path: "/" });
    loadSetting();
  }, []);
  
  const handleChangeText = (event) => {
    const wordCount = event.target.value;
    let pp;
    let ratio;
    let editorFees;
    if (wordCount >= 0 && wordCount <= 250) {
      pp = 12.5;
      ratio = 1;
      editorFees = 5;
    } else if (wordCount > 250 && wordCount <= 500) {
      pp = 25;
      ratio = 1;
      editorFees = 10;
    } else if (wordCount > 500 && wordCount <= 1000) {
      pp = 35;
      ratio = 1;
      editorFees = 12.5;
    } else if (wordCount > 1000 && wordCount <= 1500) {
      pp = 40;
      ratio = 1;
      editorFees = 14;
    } else if (wordCount >= 1500 && wordCount <= 2500) {
      pp = wordCount * 0.008 * 3.25;
      ratio = 3.25;
      editorFees = wordCount * 0.008;
    } else if (wordCount >= 2501 && wordCount <= 25000) {
      pp = wordCount * 0.007 * 3.2;
      ratio = 3.2;
      editorFees = wordCount * 0.007;
    } else if (wordCount >= 25001 && wordCount <= 50000) {
      pp = wordCount * 0.007 * 3;
      ratio = 3;
      editorFees = wordCount * 0.007;
    } else if (wordCount > 50000) {
      pp = wordCount * 0.007 * 2.9;
      ratio = 2.9;
      editorFees = wordCount * 0.007;
    } else {
      pp = 0;
      ratio = 1;
      editorFees = 5;
    }
    //for the last 2 decimal
    pp = pp.toFixed(2);

    setPrice(pp, ratio, editorFees);
    setWordlength(event.target.value);
    cookies.set("wordlength", event.target.value, { path: "/" });
    cookies.set("price", pp, { path: "/" });
    cookies.set("ratio", ratio, { path: "/" });
    cookies.set("editorFees", editorFees, { path: "/" });
    cookies.set("homePrice", pp, { path: "/" });
    // loadDelevirydate();
    axiosRequest
      .get(
        `${baseURL}/getMaxDateBeforeExpedited/` + event.target.value + `/UTC`
      )
      .then(function (response) {
        const formattedDeliveryDate = moment(response.data).format(
          "DD MMM YYYY HH:mm"
        );
        setDeliverydate(formattedDeliveryDate);
        cookies.set("deliverydate", response.data, { path: "/" });
      })
      .catch(function (error) {
        console.log(error);
      });
  };
    return(
        <>
        <section className="section-themebg innerinstantquote">
             <div className="container">
             <div className="row">
                    <div className="col-12">
                        <h1>Instant Quote</h1>                        
                    </div>
                </div>
                <div className="row">
                                     <div className="col-md-3 col-sm-6 col-12"> <div className="form-group">
                                          <label>Service Type</label>
                                          <select
                                            className="form-control"
                                            onChange={handleChange}
                                          >
                                            <option value="1" selected>
                                              Editing
                                            </option>
                                            <option value="2">Proofreading</option>
                                            <option value="3">Formating</option>
                                          </select>
                                      </div>
                                     </div>
                                     <div className="col-md-3 col-sm-6 col-12"> 
                                     
                                     <div className="form-group">
                                          <label>Delivery Date</label>
                                          <input
                                          type="text"
                                          className="form-control"
                                          value={deliverydate}
                                          disabled
                                          onChange={handleChange}
                                        />
                                      </div>
                                     </div>

                                     <div className="col-md-3 col-sm-6 col-12"> 
                                        <div className="form-group">
                                          <label>Length</label>
                                          <input
                                            type="text"
                                            value={wordlength}
                                            className="form-control"
                                            placeholder="1100 words"
                                            onChange={handleChangeText}
                                          />
                                         </div>
                                     </div>
                                     <div className="col-md-3 col-sm-6 col-12">                                      
                                          <div className="btn-main-box">
                                                <div className="form-group">
                                                  <label>Price</label>
                                                  <span className="d-block">${price}</span>
                                                </div>

                                                <div className="btn-box text-center">
                                                <Link
                                                deliverydate={deliverydate}
                                                to={{
                                                  pathname: "/quote",
                                                  state: { id: 1, name: "sabaoon", shirt: "green" },
                                                }}
                                                className="btn btn-dark get-quote"
                                              >
                                                Get Started
                                              </Link>
                                                  
                                                  <Link to="/" className="d-block free-sample">Get your free Sample</Link>
                                                </div>
                                          </div>
                                     </div>

                                </div>
             </div>
        </section>
        </>
    );

}

export default InnerInstantQuote;